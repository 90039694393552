import React from 'react'

const NotFoundPage = () => (
  <div>
    <h1>Fehler 404</h1>
    <p>Die von Ihnen angeforderte Seite wurde nicht gefunden</p>
  </div>
)

export default NotFoundPage
